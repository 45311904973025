import React from 'react';
import './style.scss';

interface Props {
    icon: string,
    fallbackText?: string
}

const GenericIcon: React.FC<Props> = ({icon, fallbackText}) => (
        <img src={icon} alt={fallbackText || 'img'}/>
    )

export default GenericIcon;