import React from 'react'
import { useHistory } from 'react-router-dom'
import SocialIcon from '../SocialIcon'
import './style.scss'
import fbIcon from '../../images/icon-facebook-blanco.png'
import igIcon from '../../images/icon-instagram-blanco.png'
import Header from '../Header'

interface Props {
    onClose: () => void
}

const Menu: React.FC<Props> = (props) => {
    const history = useHistory()

    const onMenuItemClick = (url: string) => {
        history.push(url)
        props.onClose()
    }

    return (
        <div className="menu_container">
            <Header showClose onMenuClick={props.onClose} />
            <ul>
                {/* <li>
                    <div className="menu_wrapper d-flex justify-content-center align-items-end">
                        <button className="login_button">INGRESAR A MI CUENTA</button>
                    </div>
                </li> */}
                <li className="menu_wrapper d-flex justify-content-center align-items-end" onClick={() => onMenuItemClick('/servicios')}>
                    <span>Nuestros Servicios</span>
                </li>
                <li className="menu_wrapper d-flex justify-content-center align-items-end" onClick={() => onMenuItemClick('/sucursales')}>
                    <span>Sucursales</span>
                </li>
                <li className="menu_wrapper d-flex justify-content-center align-items-end" onClick={() => onMenuItemClick('/faqs')}>
                    <span>Preguntas Frecuentes</span>
                </li>
                <li className="menu_wrapper d-flex justify-content-center align-items-end" onClick={() => onMenuItemClick('/trabaja-con-nosotros')}>
                    <span>Trabajá Con Nosotros</span>
                </li>
                <li className="menu_wrapper d-flex justify-content-center align-items-end" onClick={() => onMenuItemClick('/terminos-condiciones')}>
                    <span>Términos y condiciones</span>
                </li>
                {/* <li className="menu_wrapper d-flex justify-content-center align-items-end" onClick={() => onMenuItemClick('/cotizacion')}>
                    <span>Cotizá tu envío</span>
                </li> */}
            </ul>
            <div className="menu_footer">
                <div className="d-flex justify-content-center p-5">
                    <SocialIcon
                        icon={fbIcon}
                        big
                        socialMediaLink="https://www.facebook.com/credifinlogisticaok/" />

                    <div className="mr-2"></div>

                    <SocialIcon
                        icon={igIcon}
                        big
                        socialMediaLink="https://www.instagram.com/credifinlogistica/" />
                </div>
            </div>
        </div>
    )
}

export default Menu