import React from 'react'
import './style.scss'

interface Props {
    icon: string,
    socialMediaLink: string,
    fallbackText?: string
    big?: boolean
}

const SocialIcon: React.FC<Props> = ({icon, socialMediaLink, fallbackText, big}) => {
    return (
        <a target="__blank" href={socialMediaLink} className="icon mr-3">
            <img src={icon} className={`${big ? 'big' : 'small'} img-fluid`} alt={fallbackText || 'img'}/>
        </a>
    )
}

export default SocialIcon