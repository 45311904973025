import React from 'react'
import './style.scss'

const Loader: React.FC = () => {
    return (
        <div className="loader-container">
            <div className="loader"></div>
        </div>
    )
}

export default Loader