import React from 'react';
import './style.scss';
import logo from '../../images/footer/logo.svg'
import redes from '../../images/footer/redes.svg'
import { HashLink as Link } from 'react-router-hash-link';

function Footer() {
  return (
    <footer className="b-g tamano">
      <div className="contenedor pt-4 text-center text-md-left">
        <div className="row py-4">
          <div className="col-xl col-md-4 mb-4 mb-lg-0">
            <img src={logo} className="img-fluid" style={{ width: 170 }} />
          </div>
          <div className="col-xl col-md-4 mb-4 mb-lg-0">
            <Link to="/la-empresa"> <h6 className="text-white mb-2 text-style">La Empresa</h6></Link>
            <ul className="list-unstyled mb-0">
              <Link to="/servicios"> <li className="text-white text-style pb-1">Nuestros Servicios</li></Link>
              <Link to="/trabaja-con-nosotros"> <li className="text-white text-style pb-1">Oportunidades laborales</li></Link>
              <Link to="/la-empresa#redFranquicias"> <li className="text-white text-style pb-1">Quiero abrir una franquicia</li></Link>
              <Link to="/nuestras-marcas"> <li className="text-white text-style pb-1">Nuestras marcas</li></Link>
            </ul>
          </div>
          <div className="col-xl col-md-4 mb-4 mb-lg-0">
            <h6 className="text-white mb-2 text-style">Casa Central</h6>
            <ul className="list-unstyled mb-0">
              <li className="text-white text-style pb-1">Tel.: +54 (03482) 435-578</li>
              <li className="text-white text-style pb-1">+54 (3482) 430-144</li>
              <li className="text-white text-style pb-1">Lunes a viernes de 9 a 17 hs.</li>
            </ul>
          </div>
          <div className="col-xl col-md-4 mb-4 mb-lg-0">
            <h6 className="text-white mb-2 text-style">Ayuda</h6>
            <ul className="list-unstyled mb-0">
              <Link to="/faqs"> <li className="text-white text-style pb-1">Preguntas frecuentes</li></Link>
              <li className="text-white text-style pb-1"> Reclamos: +54 (03482) 424000</li>
            </ul>
          </div>
          <div className="col-xl col-md-4 mb-4 mb-lg-0">
            <h6 className="text-white mb-2 text-style">Seguinos en nuestras redes</h6>
            <ul className="list-unstyled mb-0">
              <div className="row justify-content-center justify-content-md-start">
                <div className="col-auto pr-0">
                  <a href="https://www.instagram.com/credifinlogistica/" rel="noreferrer" target="_blank"><li className="text-white text-style pb-1">Instagram</li></a>
                  <a href="https://www.facebook.com/credifinlogisticaok/" rel="noreferrer" target="_blank"><li className="text-white text-style pb-1">Facebook</li></a>
                </div>
                <div className="col-auto pl-0">
                  <img src={redes} className="img-fluid" style={{ width: '40px', height: '40px', position: 'relative', right: '8px', top: '5px' }} />
                </div>
              </div>
            </ul>
          </div>
        </div>
        <div className="row py-4">
          <div className="col-xl col-md-4 mb-4 mb-lg-0 order-md-1 order-4">
            <ul className="list-unstyled mb-0">
              <li className="text-color text-style pb-1">Copyright © {new Date().getFullYear()} Credifin Logística.</li>
            </ul>
          </div>
          <div className="col-xl col-md-4 mb-4 mb-lg-0 order-md-2 order-1">
            <ul className="list-unstyled mb-0">
              <Link to="/terminos-condiciones"><li className="text-color text-style pb-1">Términos y Condiciones</li></Link>
            </ul>
          </div>
          <div className="col-xl col-md-4 mb-4 mb-lg-0 order-md-3 order-2">
            <ul className="list-unstyled mb-0">
              <li className="text-color text-style pb-1">Reconquista, Santa Fe, Argentina.</li>
            </ul>
          </div>
          <div className="col-xl col-md-4 mb-4 mb-lg-0 order-md-4 order-3">
            <ul className="list-unstyled mb-0">
              <a href="https://www.enacom.gob.ar/" rel="noreferrer" target="_blank"><li className="text-color text-style pb-1">Enacom</li></a>
            </ul>
          </div>
          <div className="col-xl col-md-4 mb-4 mb-lg-0 order-md-5 order-5">
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
