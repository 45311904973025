import React, { useState, lazy, Suspense, useEffect } from 'react';
import './App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Modal from 'react-modal';
import { Container } from 'react-bootstrap';
import Menu from './components/Menu';
import ScrollToTop from './components/ScrollToTop'
import Header from './components/Header';
import Footer from './components/Footer';
import Loader from './components/Loader';
import AOS from 'aos';
import 'aos/dist/aos.css';

const HomePage = lazy(() => import('./pages/Home'));
const CotizacionExpressPage = lazy(() => import('./pages/CotizacionExpress'));
const SucursalesPage = lazy(() => import('./pages/Sucursales'));
const ServiciosPage = lazy(() => import('./pages/Servicios'));
const ProductosPage = lazy(() => import('./pages/Productos'));
const TrabajosPage = lazy(() => import('./pages/Trabajos'));
const FaqPage = lazy(() => import('./pages/Faq'));
const EnvioPage = lazy(() => import('./pages/Envio'));
const La_empresaPage = lazy(() => import('./pages/La_empresa'));
const Localidades_atendidasPage = lazy(() => import('./pages/Localidades_atendidas'));
const Terminos_condicionesPage = lazy(() => import('./pages/Terminos_condiciones'));
const NuestrasMarcasPage = lazy(() => import('./pages/NuestrasMarcas'));
const MailValidacionPage = lazy(() => import('./pages/MailValidacion'));

Modal.setAppElement('#root')

function App() {
  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  const customStyles = {
    content: {
      width: '100%',
      height: '100vh',
      top: 0,
      left: 0,
      padding: 0,
      border: 'none'
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 1000
    });
  }, []);

  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <ScrollToTop />
        <Header onMenuClick={() => {setModalOpen(true); sessionStorage.setItem("menuAbierto", "si")}} />
        <Container fluid className="p-0" style={{ overflowX: 'hidden' }}>
          <Switch>
            <Route path="/cotizacion">
              <CotizacionExpressPage />
            </Route>
            <Route path="/sucursales">
              <SucursalesPage />
            </Route>
            <Route path="/servicios">
              <ServiciosPage />
            </Route>
            <Route path="/productos">
              <ProductosPage />
            </Route>
            <Route path="/trabaja-con-nosotros">
              <TrabajosPage />
            </Route>
            <Route path="/faqs">
              <FaqPage />
            </Route>
            <Route path="/que-puedo-enviar">
              <EnvioPage />
            </Route>
            <Route path="/la-empresa">
              <La_empresaPage />
            </Route>
            <Route path="/localidades-atendidas">
              <Localidades_atendidasPage />
            </Route>
            <Route path="/terminos-condiciones">
              <Terminos_condicionesPage />
            </Route>
            <Route path="/nuestras-marcas">
              <NuestrasMarcasPage />
            </Route>
            <Route path="/mail/:id">
              <MailValidacionPage />
            </Route>
            <Route path="/">
              <HomePage isModalOpen={isModalOpen} />
            </Route>
          </Switch>
        </Container>
        <Container fluid className="p-0">
          <Footer />
        </Container>
        <Modal
          isOpen={isModalOpen}
          style={customStyles}>
          <Menu onClose={() => {setModalOpen(false); sessionStorage.setItem("menuAbierto", "no")}} />
        </Modal>
      </Suspense>
    </Router>
  )
}

export default App;
