import React, { Fragment } from 'react'
import './style.scss'
import { Container, Navbar, Nav } from 'react-bootstrap'
import logo from '../../images/header/logo.svg'
import menuIcon from '../../images/icon-menu.svg'
import SocialIcon from '../SocialIcon'
import fbIcon from '../../images/icon-facebook.png'
import igIcon from '../../images/icon-instagram.png'
import AccountIcon from '../../images/account.png'
import GenericIcon from '../GenericIcon'
import closeMenuIcon from '../../images/icon-menu-close.png'

interface Props {
    showClose?: boolean,
    onMenuClick?: () => void
}

const Header: React.FC<Props> = (props) => {
    return (
        <Fragment>
            <Navbar bg="light" className="credifin__navbar d-flex d-md-none" expand="lg">
                <Container>
                    <Navbar.Brand href="/">
                        <img className="img-fluid" src={logo} alt="logo" />
                    </Navbar.Brand>

                    <div className="d-flex d-md-none">
                        {props.showClose ? null : (
                            <React.Fragment>
                                <SocialIcon
                                    icon={fbIcon}
                                    socialMediaLink="https://www.facebook.com/credifinlogisticaok/" />

                                <SocialIcon
                                    icon={igIcon}
                                    socialMediaLink="https://www.instagram.com/credifinlogistica/" />
                            </React.Fragment>
                        )}

                        <Navbar.Toggle className="navbar-toggle p-1 mt-0" aria-controls="menu-controls" onClick={props.onMenuClick}>
                            <img src={props.showClose ? closeMenuIcon : menuIcon} alt="menu" />
                        </Navbar.Toggle>
                    </div>
                </Container>
            </Navbar>

            <Navbar className="d-none d-md-block">
                <Container className="head_container">
                    <Navbar.Brand href="/" className="head_logo">
                        <img className="img-fluid" src={logo} alt="logo" />
                    </Navbar.Brand>
                    <Nav className="d-flex justify-content-around align-items-center">
                        <Nav.Link href="/la-empresa">La Empresa</Nav.Link>
                        <Nav.Link href="/servicios">Servicios</Nav.Link>
                        <Nav.Link href="/sucursales">Sucursales</Nav.Link>
                        <Nav.Link href="/faqs">Preguntas Frecuentes</Nav.Link>
                        {/* <Nav.Link href="/">Contacto</Nav.Link> */}
                        {/* <Nav.Link href="/cotizacion">Cotizá tu envío</Nav.Link> */}
                        <Nav.Link style={{ cursor: 'auto' }}>
                            <div className="account-button-content">
                                <div className="icon-content">
                                    <GenericIcon
                                        icon={AccountIcon}
                                    />
                                </div>
                                {/* <button>MI CUENTA</button> */}
                            </div>
                        </Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
        </Fragment>
    )
}

export default Header